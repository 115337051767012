<template>
  <div class="spinner">
    <div class="justify-content-center mb-3">
      <b-spinner
        style="width: 3rem; height: 3rem"
        variant="primary"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";

export default {
  components: { BSpinner },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  z-index: 10;
  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
</style>
